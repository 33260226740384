import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { BookProjectDto, SharedBookProjectDto } from '../models/book-project.model';
import { ChapterDto } from '../models/chapter.model';
import { BookProjectApiService } from './book-project-api.service';
import { firstValueFrom } from 'rxjs';

// Load default (Roboto) fonts
pdfMake.vfs = pdfFonts.default.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class BookProjectPdfService {
  constructor(private bookProjectService: BookProjectApiService) {}

  public async generatePdfFromSharedBookProject(sharedBookProject: SharedBookProjectDto): Promise<void> {
    const bookProjectObservable = this.bookProjectService.getBookProjectById(sharedBookProject.id);
    const bookProject = await firstValueFrom(bookProjectObservable);
    await this.generatePdf(bookProject);
  }

  public async generatePdf(bookProject: BookProjectDto): Promise<void> {
    const docDefinition = {
      // A4 with larger left/right margins to reduce line length
      pageSize: 'A4',
      pageMargins: [90, 60, 90, 60],  // left, top, right, bottom

      content: [
        // Title Page
        {
          text: bookProject.title,
          style: 'titlePage',
        },
        { text: '', pageBreak: 'after' },

        // Chapters
        ...this.generateChapterContents(bookProject),
      ],

      styles: {
        titlePage: {
          fontSize: 24,
          bold: true,
          margin: [0, 100, 0, 20], // place the title a bit down the page
          alignment: 'center',
        },
        chapterTitle: {
          fontSize: 18,
          bold: true,
          margin: [0, 20, 0, 20],
          alignment: 'center',
        },
        bodyText: {
          fontSize: 13,       // slightly larger font
          lineHeight: 1.6,    // increase line spacing
          alignment: 'justify',
          margin: [0, 0, 0, 10],
        },
      },

      defaultStyle: {
        // Uses Roboto by default; no custom fonts required
      },
    };

    pdfMake.createPdf(docDefinition).download(`${bookProject.title}.pdf`);
  }

  private generateChapterContents(bookProject: BookProjectDto): any[] {
    const chapterContents: any[] = [];

    bookProject.chapters?.forEach((chapter: ChapterDto, index) => {
      // Page break before each chapter except the first
      if (index !== 0) {
        chapterContents.push({ text: '', pageBreak: 'before' });
      }

      // Chapter title
      chapterContents.push({
        text: chapter.title,
        style: 'chapterTitle',
      });

      // Split the fullText into paragraphs
      const paragraphs = chapter.fullText.split('\n').filter((p) => p.trim() !== '');
      paragraphs.forEach((paragraph) => {
        chapterContents.push({
          text: paragraph.trim(),
          style: 'bodyText',
        });
      });
    });

    return chapterContents;
  }
}
