import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentUrlService } from './environment-url.service';
import { BookProjectDto } from '../models/book-project.model';

@Injectable({
  providedIn: 'root',
})
export class BookProjectEpubService {
  private baseUrl = 'epub';

  constructor(
    private http: HttpClient,
    private envUrl: EnvironmentUrlService
  ) {}

  private createCompleteRoute(route: string, envAddress: string): string {
    return `${envAddress}/${this.baseUrl}/${route}`;
  }

  public downloadEpub(bookProjectId: string): Observable<Blob> {
    const url = this.createCompleteRoute(`download/${bookProjectId}`, this.envUrl.apiUrl);
    return this.http.get(url, {
      responseType: 'blob'
    });
  }

  public validateEpub(bookProjectId: string): Observable<EpubValidationResult> {
    const url = this.createCompleteRoute(`validate/${bookProjectId}`, this.envUrl.apiUrl);
    return this.http.get<EpubValidationResult>(url);
  }
}

export interface EpubValidationResult {
  isValid: boolean;
  bookProjectId: string;
  title: string;
  issues: string[];
}
