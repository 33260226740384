import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface BookProjectContext {
  page: string;
  bookProjectId?: string;
  specificPartId?: string;
}

interface RoutePattern {
  regex: RegExp;
  page: string;
}

@Injectable({
  providedIn: 'root'
})
export class BookProjectContextService {
  private _context = new BehaviorSubject<BookProjectContext | null>(null);
  public context$: Observable<BookProjectContext | null> = this._context.asObservable();

  private routePatterns: RoutePattern[] = [
    { regex: /^\/dashboard\/projects$/, page: 'projects' },
    { regex: /^\/dashboard\/discover$/, page: 'discover' },
    { regex: /^\/dashboard\/wallet$/, page: 'wallet' },
    { regex: /^\/dashboard\/wallet\/buy$/, page: 'buy-credits' },
    { regex: /^\/dashboard\/user\/profile$/, page: 'profile' },
    { regex: /^\/dashboard\/projects\/([^\/]+)$/, page: 'project' },
    { regex: /^\/dashboard\/projects\/([^\/]+)\/landing$/, page: 'landing' },
    { regex: /^\/dashboard\/projects\/([^\/]+)\/writing-style$/, page: 'writing-style' },
    { regex: /^\/dashboard\/projects\/([^\/]+)\/background$/, page: 'background' },
    { regex: /^\/dashboard\/projects\/([^\/]+)\/characters$/, page: 'characters' },
    { regex: /^\/dashboard\/projects\/([^\/]+)\/characters\/([^\/]+)$/, page: 'character' },
    { regex: /^\/dashboard\/projects\/([^\/]+)\/research-notes$/, page: 'research-notes' },
    { regex: /^\/dashboard\/projects\/([^\/]+)\/research-notes\/([^\/]+)$/, page: 'research-note' },
    { regex: /^\/dashboard\/projects\/([^\/]+)\/outline$/, page: 'outline' },
    { regex: /^\/dashboard\/projects\/([^\/]+)\/chapters$/, page: 'chapters' },
    { regex: /^\/dashboard\/projects\/([^\/]+)\/chapters\/([^\/]+)$/, page: 'chapter' },
    { regex: /^\/dashboard\/projects\/([^\/]+)\/download$/, page: 'download' },
  ];

  constructor(private router: Router) {
    this.router.events.pipe(
      filter((event: RouterEvent): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.updateContext(event.urlAfterRedirects);
    });

    // Initial context update
    this.updateContext(this.router.url);
  }

  private updateContext(url: string) {
    console.log('Updating context for URL:', url);

    let context: BookProjectContext = {
      page: 'unknown',
      bookProjectId: undefined,
      specificPartId: undefined
    };

    for (const pattern of this.routePatterns) {
      const match = url.match(pattern.regex);
      if (match) {
        context.page = pattern.page;
        context.bookProjectId = match[1];
        if (match[2]) {
          context.specificPartId = match[2];
        }
        console.log('Matched pattern:', pattern.page, 'Context:', context);
        break;
      }
    }

    if (context.page === 'unknown') {
      console.warn('No matching route pattern found for URL:', url);
    }

    const currentContext = this._context.value;
    if (JSON.stringify(currentContext) !== JSON.stringify(context)) {
      console.log('Context changed. New context:', context);
      this._context.next(context);
    } else {
      console.log('Context unchanged');
    }
  }

  getCurrentContext(): BookProjectContext | null {
    return this._context.value;
  }

  public refreshContext() {
    this.updateContext(this.router.url);
  }
}
