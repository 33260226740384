import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import { catchError, filter, switchMap, take, finalize } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private userService: UserService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401 && !this.isPublicRoute(request.url)) {
          return this.handle401Error(request, next);
        }

        return this.handleOtherError(err);
      })
    );
  }

  private isPublicRoute(url: string): boolean {
    return url.includes('/users/login') || url.includes('/users/register') || url.includes('/users/confirm-email');
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.userService.refreshToken().pipe(
        switchMap((res) => {
          this.isRefreshing = false;
          if (res.isAuthSuccessful) {
            this.refreshTokenSubject.next(res.token);
            return next.handle(this.addTokenToRequest(request, res.token));
          } else {
            return this.logoutAndRedirect();
          }
        }),
        catchError(() => {
          this.isRefreshing = false;
          return this.logoutAndRedirect();
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          return next.handle(this.addTokenToRequest(request, token));
        })
      );
    }
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    console.log('Adding token to request:', token);
    return request.clone({
      setHeaders: { Authorization: `Bearer ${token}` }
    });
  }

  private logoutAndRedirect(): Observable<HttpEvent<any>> {
    this.userService.logout();
    this.router.navigate(['/account/login']);
    return of();
  }

  private handleOtherError(err: any): Observable<never> {
    console.error('An error occurred:', err);
    throw err;
  }
}
