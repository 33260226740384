<div class="book-viewer" *ngIf="sharedBookProject">
  <header class="book-header">
    <div class="book-title-section">
      <h1 class="book-title">{{ sharedBookProject.title }}</h1>
      <p class="book-author">By {{ sharedBookProject.author }}</p>
    </div>

    <div class="book-content-section">
      <div class="book-cover">
        <img [src]="bookCoverUrl" alt="Book cover" onerror="this.remove()">
      </div>
      <div class="book-description" *ngIf="sharedBookProject.description">
        <p>{{ sharedBookProject.description }}</p>
      </div>
    </div>

    <div class="book-interaction-bar">
      <div class="metrics-section">
        <div class="metric-item">
          <i class="mdi mdi-eye"></i>
          <span>{{ sharedBookProject.metrics.uniqueViews + 1 }} views</span>
        </div>
        <div class="metric-item">
          <button
            class="like-button"
            [class.liked]="sharedBookProject.metrics.isLikedByCurrentUser"
            [class.disabled]="!canLikeBook()"
            (click)="toggleLike()"
            [title]="!isAuthenticated ? 'Please sign in to like this book' :
                    currentUserId === sharedBookProject.applicationUserId ? 'You cannot like your own book' :
                    'Like this book'">
            <i class="mdi" [class.mdi-heart]="sharedBookProject.metrics.isLikedByCurrentUser"
               [class.mdi-heart-outline]="!sharedBookProject.metrics.isLikedByCurrentUser"></i>
            <span>{{ sharedBookProject.metrics.totalLikes }}</span>
          </button>
        </div>
      </div>
      <div class="share-section">
        <button class="share-button" (click)="shareBook()" [class.pressed]="isButtonPressed">
          <i class="mdi mdi-share-variant"></i>
          <span>{{ shareButtonText }}</span>
        </button>
      </div>
    </div>

    <div class="download-section">
      <div class="download-buttons">
        <button class="download-btn" (click)="downloadPdf()" [disabled]="isDownloadingPdf">
          <i class="mdi mdi-file-pdf-box"></i>
          <span>PDF</span>
          <i *ngIf="isDownloadingPdf" class="mdi mdi-loading mdi-spin loading-icon"></i>
        </button>
        <button class="download-btn" (click)="downloadEpub()" [disabled]="isDownloadingEpub">
          <i class="mdi mdi-book-variant"></i>
          <span>EPUB</span>
          <i *ngIf="isDownloadingEpub" class="mdi mdi-loading mdi-spin loading-icon"></i>
        </button>
      </div>

      <div *ngIf="downloadWarning" class="download-message warning">
        <i class="mdi mdi-alert"></i>
        {{ downloadWarning }}
      </div>
      <div *ngIf="downloadError" class="download-message error">
        <i class="mdi mdi-alert-circle"></i>
        {{ downloadError }}
      </div>
    </div>

    <div id="google_translate_element" class="translate-element"></div>
    <div class="reader-apps" *ngIf="isMobile">
      <p class="reader-label">Listen with Elevenlabs Reader:</p>
      <div class="mobile-actions">
        <button (click)="openElevenLabsReader()" class="open-in-app-btn">
          Open in Elevenlabs Reader
        </button>
      </div>
    </div>
  </header>

  <div class="book-content">
    <div class="chapters-nav">
      <ul>
        <li *ngFor="let chapter of sharedBookProject.chapters; let i = index">
          <a (click)="scrollToChapter($event, i)" [class.active]="currentChapter === i">{{ chapter.title }}</a>
        </li>
      </ul>
    </div>

    <div class="chapters-content">
      <div class="chapter" *ngFor="let chapter of sharedBookProject.chapters; let i = index" [id]="'chapter-' + i">
        <h2 class="chapter-title">{{ chapter.title }}</h2>
        <div class="chapter-content">
          <p *ngFor="let paragraph of splitIntoParagraphs(chapter.fullText)" [innerHTML]="preserveNewlines(paragraph)"></p>
        </div>
      </div>
    </div>
  </div>
</div>
